import React from 'react';
import './App.css';
// import BasicMap from './components/BasicMap';
import EasyMap from './components/EasyMap';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';



function App() {

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    cache,


    uri: 'https://green-api.teamsystems.de/graphql',
    // uri: 'http://192.168.178.20:4000/graphql',

  });





  return (
    <ApolloProvider client={client}>
      <div >
        <header >


          <EasyMap />
        </header>
      </div>
    </ApolloProvider>
  );
}

export default App;
