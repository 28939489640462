import React, { useState } from 'react';
// import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { GeoJSONLayer, Layer } from 'react-mapbox-gl';

import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';



const geojson = require('./geojson.json');

const features = require('./features.json');
const tempelschoen = require('../data/tempelschoen.json');
const GET_INQUIRIES = gql`
query getInquiries{
    getInquiries {
        date
        title
        politicalField1
        geometryType
        geometryCoordinates
  }
}
`


console.log("Features ", features);




// class BasicMap extends React.Components {



const mapStyle = {
    flex: 1
};

//     // constructor(props: Props) {
//     //     super(props);
//     //     this.state = {
//     //       lng: 5,
//     //       lat: 34,
//     //       zoom: 1.5
//     //     };
//     //   }
// }

// const geoJson = {
//     'type': 'FeatureCollection',
//     'features': [
//         {
//             // feature for Mapbox DC
//             'type': 'Feature',
//             'geometry': {
//                 'type': 'Point',
//                 'coordinates': [
//                      52.429221, 13.393486
//                 ]
//             },
//             'properties': {
//                 'title': 'Mapbox DC',
//                 'icon': 'monument'
//             }
//         },
//         {
//             // feature for Mapbox DC
//             'type': 'Feature',
//             'geometry': {
//                 'type': 'Point',
//                 'coordinates': [
//                      13.393486, 52.429221
//                 ]
//             },
//             'properties': {
//                 'title': 'Mapbox DC',
//                 'icon': 'monument'
//             }
//         }
//     ]
//     };

// const styles = {
//     width: "60%",
//     height: "80%",
//     position: "absolute"
// };

// const token ="pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA";
const styles = {
    "londonCycle": "mapbox://styles/mapbox/light-v9",
    "light": "mapbox://styles/mapbox/light-v9",
    "dark": "mapbox://styles/mapbox/dark-v9",
    "basic": "mapbox://styles/mapbox/basic-v9",
    "outdoor": "mapbox://styles/mapbox/outdoors-v10"
};

// const objectProperty = {
//     'title': 'Mapbox DC',
//     'icon': 'monument'
// };

const EasyMap = (props) => {

    const [featureIndex, setFeatureIndex] = useState(0);
    const [geoData, setGeoData] = useState({
        "type": "FeatureCollection",
        "features": []
    });


    const { data: inquiryData, loading: getInquiriesLoading, error } = useQuery(GET_INQUIRIES);

    console.log("Inquiry data", inquiryData);

    const [view, setView] = useState('top');

    const [mapStyle, setMapStyle] = useState('dark');

    const boroughData = {
        "type": "FeatureCollection",
        "features": [tempelschoen]
    };

    // const center =   [-77.01239, 38.91275] ;

    let zoom = 0;
    const center = [13.382162225181332, 52.45055474530463];
    if (view === 'top') {
        zoom = [10.524396649045745];
    } else {
        zoom = [11.324396649045745];
    }

    const Map = ReactMapboxGl({
        accessToken:
            //   'pk.eyJ1IjoiZmFicmljOCIsImEiOiJjaWc5aTV1ZzUwMDJwdzJrb2w0dXRmc2d0In0.p6GGlfyV-WksaDV_KdN27A'
            'pk.eyJ1Ijoic2NoaW5nZWxkaSIsImEiOiJjazdidjY0YzMwN3IyM21wYTU4NDJjZG9nIn0.cLQxm_jUnXpMgUuVfWMntQ'
    });


    const boroughLineLayout = {
        'line-cap': 'round',
        'line-join': 'round'
    };

    const boroughLinePaint = {
        'line-color': '#3a9435',
        'line-width': 2
    };


    const lineLayout = {
        'line-cap': 'round',
        'line-join': 'round'
    };

    const linePaint = {
        'line-color': '#4790E5',
        'line-width': 4
    };


    const symbolLayout = {
        'text-field': '{place}',
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [0, 0.6],
        'text-anchor': 'top'
    };
    const symbolPaint = {
        'text-color': 'white'
    };

    const circleLayout = { visibility: 'visible' };
    const circlePaint = {
        'circle-color': 'white'
    };

    const onStyleLoad = (map) => {
        const { onStyleLoad } = props;
        return onStyleLoad && onStyleLoad(map);
    };

    const onClickCircle = (evt) => {
        console.log(evt);
    }
    const clickButton = () => {
        const data =
        {
            "type": "FeatureCollection",
            "features": []
        }

        const geoData = {
            pointData: { ...data },
            lineData: { ...data }
        }



        if (inquiryData !== undefined) {

            inquiryData.getInquiries.forEach((item, index) => {

                const object = {
                    "type": "Feature",
                    "geometry": {
                        "type": item.geometryType,
                        "coordinates": JSON.parse(item.geometryCoordinates)
                    },
                    "properties": {
                        "title": "",
                        "icon": "",
                        "place": item.title,
                    }
                };


                if (item.geometryType === 'Point') {
                    geoData.pointData.features.push(object);
                } else if (item.geometryType === 'LineString') {
                    geoData.lineData.features.push(object);
                }
            });

            setGeoData(geoData);



            // data.features.push({
            //     "type": "Feature",
            //     "geometry": {
            //       "type": inquiryData.getInquiries[0].geometryType,
            //       "coordinates": JSON.parse(inquiryData.getInquiries[0].geometryCoordinates)
            //     },
            //     "properties": {
            //       "title": "Mapbox DC",
            //       "icon": "monument",
            //       "place": inquiryData.getInquiries[0].title,
            //     }
            //   },);
            // setGeoData(data);

        }
    }

    const handleViewChange = (event, newView) => {
        console.log("handleViewChange", newView);
        setView(newView == null ? 'top' : 'building');
    }


    const paintLayer = {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': {
            type: 'identity',
            property: 'height'
        },
        'fill-extrusion-base': {
            type: 'identity',
            property: 'min_height'
        },
        'fill-extrusion-opacity': 0.6
    };


    console.log("Geo Data ", geoData );
    return (
        <div>
            
            <ToggleButtonGroup size="small" value="top" exclusive onChange={handleViewChange}>
                <ToggleButton key={1} value="top">
                    Oben Ansicht
                </ToggleButton>,
                <ToggleButton key={2} value="building">
                    Gebäude Ansicht
                </ToggleButton>
            </ToggleButtonGroup>
            <br /><br/>           <Button variant="contained" onClick={() => clickButton()}>Zeige Erfolge</Button>
            <Map
                style={styles[mapStyle]}
                containerStyle={{
                    height: '70vh',
                    width: '70vh'
                }}
                center={center}
                zoom={zoom}
                pitch={[view === 'building' ? 45 : 0]}
                //   containerStyle={mapStyle}
                onStyleLoad={onStyleLoad}
            >

                {view === 'building' &&
                    <Layer
                        id="3d-buildings"
                        sourceId="composite"
                        sourceLayer="building"
                        filter={['==', 'extrude', 'true']}
                        type="fill-extrusion"
                        minZoom={14}
                        paint={paintLayer}
                    />
                }

                <GeoJSONLayer
                    data={boroughData}


                    // data={geojson}
                    // circleLayout={circleLayout}
                    // circlePaint={circlePaint}
                    // circleOnClick={onClickCircle}
                    // symbolLayout={symbolLayout}
                    // symbolPaint={symbolPaint}
                    lineLayout={boroughLineLayout}
                    linePaint={boroughLinePaint}
                />


                <GeoJSONLayer
                    data={geoData.pointData}
                    // data={geojson}
                    circleLayout={circleLayout}
                    circlePaint={circlePaint}
                    // circleOnClick={onClickCircle}
                    // symbolLayout={symbolLayout}
                    // symbolPaint={symbolPaint}
                    // lineLayout={lineLayout}
                    // linePaint={linePaint}
                />

                <GeoJSONLayer
                    data={geoData.lineData}
                    // data={geojson}
                    // circleLayout={circleLayout}
                    // circlePaint={circlePaint}
                    // circleOnClick={onClickCircle}
                    symbolLayout={symbolLayout}
                    symbolPaint={symbolPaint}
                    lineLayout={lineLayout}
                    linePaint={linePaint}
                />
            </Map>
        </div>
    );












    //     return (
    //         <div>
    //             <Map
    //   style="mapbox://styles/mapbox/streets-v9"
    //   containerStyle={{
    //     height: '100vh',
    //     width: '100vw'
    //   }}
    // >
    //   {/* <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
    //     <Feature coordinates={[-0.481747846041145, 51.3133379650232]} properties={objectProperty}/>
    //   </Layer> */}

    //   <Layer
    //         type="symbol"
    //         id="marker"
    //         layout={{ "icon-image": "marker-15" }}>
    //         {/* <Feature coordinates={[-0.481747846041145, 51.3233379650232]}/> */}


    // <GeoJSONLayer
    //   data="https://docs.mapbox.com/help/data/stations.geojson"
    //   symbolLayout={{
    //     "text-field": "{place}",
    //     "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
    //     "text-offset": [0, 0.6],
    //     "text-anchor": "top"
    //   }}/>
    //   </Layer>
    // </Map>;
    //         </div>
    //     )

}

export default EasyMap;